var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      { staticClass: "breadcrumb" },
      [
        _c(
          "el-button",
          { attrs: { type: "primary" }, on: { click: _vm.confirmForm } },
          [_vm._v(_vm._s(_vm.$t("button.preservation")))]
        ),
        _c(
          "el-button",
          {
            on: {
              click: function ($event) {
                return _vm.$router.go(-1)
              },
            },
          },
          [_vm._v("取消")]
        ),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "bgFFF paddingT20 paddingLR20" },
      [
        _c(
          "el-form",
          {
            ref: "ruleForm",
            attrs: {
              model: _vm.ruleForm,
              rules: _vm.rules,
              "label-width": "165px",
              "label-position": "right",
            },
          },
          [
            _c(
              "el-row",
              { attrs: { gutter: 50 } },
              [
                _c(
                  "el-col",
                  { attrs: { span: 12 } },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "发票平台:", prop: "type" } },
                      [
                        _c(
                          "el-radio-group",
                          {
                            model: {
                              value: _vm.ruleForm.type,
                              callback: function ($$v) {
                                _vm.$set(_vm.ruleForm, "type", $$v)
                              },
                              expression: "ruleForm.type",
                            },
                          },
                          [
                            _c("el-radio", { attrs: { label: 0 } }, [
                              _vm._v("航信"),
                            ]),
                            _c("el-radio", { attrs: { label: 2 } }, [
                              _vm._v("航信诺诺"),
                            ]),
                            _c("el-radio", { attrs: { label: 3 } }, [
                              _vm._v("诺诺数电"),
                            ]),
                            _c("el-radio", { attrs: { label: 1 } }, [
                              _vm._v("百旺"),
                            ]),
                            _c("el-radio", { attrs: { label: 4 } }, [
                              _vm._v("百旺云"),
                            ]),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      { attrs: { label: "商户名称:", prop: "operationId" } },
                      [
                        _c(
                          "el-select",
                          {
                            attrs: { filterable: "" },
                            model: {
                              value: _vm.ruleForm.operationId,
                              callback: function ($$v) {
                                _vm.$set(_vm.ruleForm, "operationId", $$v)
                              },
                              expression: "ruleForm.operationId",
                            },
                          },
                          _vm._l(_vm.operationList, function (item) {
                            return _c("el-option", {
                              key: item.operationId,
                              attrs: {
                                label: item.operationName,
                                value: item.operationId,
                              },
                            })
                          }),
                          1
                        ),
                      ],
                      1
                    ),
                    _vm.ruleForm.type === 0
                      ? _c(
                          "div",
                          [
                            _c(
                              "div",
                              { staticClass: "content" },
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: "开票项目:",
                                      prop: "invoiceItem",
                                    },
                                  },
                                  [
                                    _c(
                                      "el-select",
                                      {
                                        model: {
                                          value: _vm.ruleForm.invoiceItem,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.ruleForm,
                                              "invoiceItem",
                                              $$v
                                            )
                                          },
                                          expression: "ruleForm.invoiceItem",
                                        },
                                      },
                                      _vm._l(
                                        _vm.businessOrderType,
                                        function (item) {
                                          return _c("el-option", {
                                            key: item.code,
                                            attrs: {
                                              label: item.itemName,
                                              value: item.code,
                                            },
                                          })
                                        }
                                      ),
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: "订单类型",
                                      prop: "businessType",
                                    },
                                  },
                                  [
                                    _c(
                                      "el-checkbox-group",
                                      {
                                        model: {
                                          value: _vm.ruleForm.businessType,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.ruleForm,
                                              "businessType",
                                              $$v
                                            )
                                          },
                                          expression: "ruleForm.businessType",
                                        },
                                      },
                                      [
                                        _c(
                                          "el-checkbox",
                                          { attrs: { label: "0" } },
                                          [_vm._v("停车支付订单")]
                                        ),
                                        _c(
                                          "el-checkbox",
                                          { attrs: { label: "3" } },
                                          [_vm._v("停车卡订单")]
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: "计税方式:",
                                      prop: "taxMode",
                                    },
                                  },
                                  [
                                    _c(
                                      "el-select",
                                      {
                                        model: {
                                          value: _vm.ruleForm.taxMode,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.ruleForm,
                                              "taxMode",
                                              $$v
                                            )
                                          },
                                          expression: "ruleForm.taxMode",
                                        },
                                      },
                                      [
                                        _c("el-option", {
                                          attrs: {
                                            label: "一般纳税人",
                                            value: "0",
                                          },
                                        }),
                                        _c("el-option", {
                                          attrs: {
                                            label: "小规模纳税人",
                                            value: "1",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: "税率（%）:",
                                      prop: "taxRate",
                                    },
                                  },
                                  [
                                    _c(
                                      "el-select",
                                      {
                                        model: {
                                          value: _vm.ruleForm.taxRate,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.ruleForm,
                                              "taxRate",
                                              $$v
                                            )
                                          },
                                          expression: "ruleForm.taxRate",
                                        },
                                      },
                                      _vm._l(
                                        _vm.invoiceResource,
                                        function (item, index) {
                                          return _c("el-option", {
                                            key: index,
                                            attrs: { label: item, value: item },
                                          })
                                        }
                                      ),
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: "含税标志:",
                                      prop: "includeTax",
                                    },
                                  },
                                  [
                                    _c(
                                      "el-select",
                                      {
                                        model: {
                                          value: _vm.ruleForm.includeTax,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.ruleForm,
                                              "includeTax",
                                              $$v
                                            )
                                          },
                                          expression: "ruleForm.includeTax",
                                        },
                                      },
                                      [
                                        _c("el-option", {
                                          attrs: {
                                            label: "不含税",
                                            value: "0",
                                          },
                                        }),
                                        _c("el-option", {
                                          attrs: { label: "含税", value: "1" },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "el-form-item",
                              {
                                attrs: { label: "平台编码:", prop: "username" },
                              },
                              [
                                _c("el-input", {
                                  attrs: {
                                    maxlength: "30",
                                    "show-word-limit": "",
                                    placeholder: "请输入平台编码",
                                  },
                                  model: {
                                    value: _vm.ruleForm.username,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.ruleForm, "username", $$v)
                                    },
                                    expression: "ruleForm.username",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "授权码:",
                                  prop: "authorizationCode",
                                },
                              },
                              [
                                _c("el-input", {
                                  attrs: {
                                    maxlength: "36",
                                    "show-word-limit": "",
                                    placeholder: "请输入授权码",
                                  },
                                  model: {
                                    value: _vm.ruleForm.authorizationCode,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.ruleForm,
                                        "authorizationCode",
                                        $$v
                                      )
                                    },
                                    expression: "ruleForm.authorizationCode",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "请求地址:",
                                  prop: "requestUrl",
                                },
                              },
                              [
                                _c("el-input", {
                                  attrs: {
                                    maxlength: "200",
                                    "show-word-limit": "",
                                    placeholder: "请输入请求地址",
                                  },
                                  model: {
                                    value: _vm.ruleForm.requestUrl,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.ruleForm, "requestUrl", $$v)
                                    },
                                    expression: "ruleForm.requestUrl",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "请求码:",
                                  prop: "requestCode",
                                },
                              },
                              [
                                _c("el-input", {
                                  attrs: {
                                    maxlength: "36",
                                    "show-word-limit": "",
                                    placeholder: "请输入请求码",
                                  },
                                  model: {
                                    value: _vm.ruleForm.requestCode,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.ruleForm, "requestCode", $$v)
                                    },
                                    expression: "ruleForm.requestCode",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "请求函数:",
                                  prop: "requestMethod",
                                },
                              },
                              [
                                _c("el-input", {
                                  attrs: {
                                    maxlength: "40",
                                    "show-word-limit": "",
                                    placeholder: "请输入请求函数",
                                  },
                                  model: {
                                    value: _vm.ruleForm.requestMethod,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.ruleForm,
                                        "requestMethod",
                                        $$v
                                      )
                                    },
                                    expression: "ruleForm.requestMethod",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "目标命名空间:",
                                  prop: "targetNamespace",
                                },
                              },
                              [
                                _c("el-input", {
                                  attrs: {
                                    maxlength: "200",
                                    "show-word-limit": "",
                                    placeholder: "请输入目标命名空间",
                                  },
                                  model: {
                                    value: _vm.ruleForm.targetNamespace,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.ruleForm,
                                        "targetNamespace",
                                        $$v
                                      )
                                    },
                                    expression: "ruleForm.targetNamespace",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.ruleForm.type === 1
                      ? _c(
                          "div",
                          [
                            _c(
                              "div",
                              { staticClass: "content" },
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: "开票项目:",
                                      prop: "invoiceItem",
                                    },
                                  },
                                  [
                                    _c(
                                      "el-select",
                                      {
                                        model: {
                                          value: _vm.ruleForm.invoiceItem,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.ruleForm,
                                              "invoiceItem",
                                              $$v
                                            )
                                          },
                                          expression: "ruleForm.invoiceItem",
                                        },
                                      },
                                      _vm._l(
                                        _vm.businessOrderType,
                                        function (item) {
                                          return _c("el-option", {
                                            key: item.code,
                                            attrs: {
                                              label: item.itemName,
                                              value: item.code,
                                            },
                                          })
                                        }
                                      ),
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: "订单类型",
                                      prop: "businessType",
                                    },
                                  },
                                  [
                                    _c(
                                      "el-checkbox-group",
                                      {
                                        model: {
                                          value: _vm.ruleForm.businessType,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.ruleForm,
                                              "businessType",
                                              $$v
                                            )
                                          },
                                          expression: "ruleForm.businessType",
                                        },
                                      },
                                      [
                                        _c(
                                          "el-checkbox",
                                          { attrs: { label: "0" } },
                                          [_vm._v("停车支付订单")]
                                        ),
                                        _c(
                                          "el-checkbox",
                                          { attrs: { label: "3" } },
                                          [_vm._v("停车卡订单")]
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: "计税方式:",
                                      prop: "taxMode",
                                    },
                                  },
                                  [
                                    _c(
                                      "el-select",
                                      {
                                        model: {
                                          value: _vm.ruleForm.taxMode,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.ruleForm,
                                              "taxMode",
                                              $$v
                                            )
                                          },
                                          expression: "ruleForm.taxMode",
                                        },
                                      },
                                      [
                                        _c("el-option", {
                                          attrs: {
                                            label: "一般纳税人",
                                            value: "0",
                                          },
                                        }),
                                        _c("el-option", {
                                          attrs: {
                                            label: "小规模纳税人",
                                            value: "1",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: "税率（%）:",
                                      prop: "taxRate",
                                    },
                                  },
                                  [
                                    _c(
                                      "el-select",
                                      {
                                        model: {
                                          value: _vm.ruleForm.taxRate,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.ruleForm,
                                              "taxRate",
                                              $$v
                                            )
                                          },
                                          expression: "ruleForm.taxRate",
                                        },
                                      },
                                      _vm._l(
                                        _vm.invoiceResource,
                                        function (item, index) {
                                          return _c("el-option", {
                                            key: index,
                                            attrs: { label: item, value: item },
                                          })
                                        }
                                      ),
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: "含税标志:",
                                      prop: "includeTax",
                                    },
                                  },
                                  [
                                    _c(
                                      "el-select",
                                      {
                                        model: {
                                          value: _vm.ruleForm.includeTax,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.ruleForm,
                                              "includeTax",
                                              $$v
                                            )
                                          },
                                          expression: "ruleForm.includeTax",
                                        },
                                      },
                                      [
                                        _c("el-option", {
                                          attrs: {
                                            label: "不含税",
                                            value: "0",
                                          },
                                        }),
                                        _c("el-option", {
                                          attrs: { label: "含税", value: "1" },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: "征税方式:",
                                      prop: "taxationMethod",
                                    },
                                  },
                                  [
                                    _c(
                                      "el-select",
                                      {
                                        model: {
                                          value: _vm.ruleForm.taxationMethod,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.ruleForm,
                                              "taxationMethod",
                                              $$v
                                            )
                                          },
                                          expression: "ruleForm.taxationMethod",
                                        },
                                      },
                                      [
                                        _c("el-option", {
                                          attrs: {
                                            label: "普通征税",
                                            value: "0",
                                          },
                                        }),
                                        _c("el-option", {
                                          attrs: {
                                            label: "差额征税",
                                            value: "2",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "el-form-item",
                              {
                                attrs: { label: "登录税号:", prop: "username" },
                              },
                              [
                                _c("el-input", {
                                  attrs: {
                                    maxlength: "30",
                                    "show-word-limit": "",
                                    placeholder: "请输入登录税号",
                                  },
                                  model: {
                                    value: _vm.ruleForm.username,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.ruleForm, "username", $$v)
                                    },
                                    expression: "ruleForm.username",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "el-form-item",
                              { attrs: { label: "APPKEY:", prop: "appKey" } },
                              [
                                _c("el-input", {
                                  attrs: {
                                    maxlength: "36",
                                    "show-word-limit": "",
                                    placeholder: "请输入APPKEY",
                                  },
                                  model: {
                                    value: _vm.ruleForm.appKey,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.ruleForm, "appKey", $$v)
                                    },
                                    expression: "ruleForm.appKey",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "APPSECRET:",
                                  prop: "appSecret",
                                },
                              },
                              [
                                _c("el-input", {
                                  attrs: {
                                    maxlength: "36",
                                    "show-word-limit": "",
                                    placeholder: "请输入APPSECRET",
                                  },
                                  model: {
                                    value: _vm.ruleForm.appSecret,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.ruleForm, "appSecret", $$v)
                                    },
                                    expression: "ruleForm.appSecret",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "请求地址:",
                                  prop: "requestUrl",
                                },
                              },
                              [
                                _c("el-input", {
                                  attrs: {
                                    maxlength: "200",
                                    "show-word-limit": "",
                                    placeholder: "请输入请求地址",
                                  },
                                  model: {
                                    value: _vm.ruleForm.requestUrl,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.ruleForm, "requestUrl", $$v)
                                    },
                                    expression: "ruleForm.requestUrl",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "el-form-item",
                              {
                                attrs: { label: "加密盐值:", prop: "userSalt" },
                              },
                              [
                                _c("el-input", {
                                  attrs: {
                                    maxlength: "36",
                                    "show-word-limit": "",
                                    placeholder: "请输入加密盐值",
                                  },
                                  model: {
                                    value: _vm.ruleForm.userSalt,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.ruleForm, "userSalt", $$v)
                                    },
                                    expression: "ruleForm.userSalt",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "el-form-item",
                              { attrs: { label: "接入码:", prop: "password" } },
                              [
                                _c("el-input", {
                                  attrs: {
                                    maxlength: "30",
                                    "show-word-limit": "",
                                    placeholder: "请输入接入码",
                                  },
                                  model: {
                                    value: _vm.ruleForm.password,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.ruleForm, "password", $$v)
                                    },
                                    expression: "ruleForm.password",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "开票点编码:",
                                  prop: "pointCode",
                                },
                              },
                              [
                                _c("el-input", {
                                  attrs: {
                                    maxlength: "30",
                                    "show-word-limit": "",
                                    placeholder: "请输入开票点编码",
                                  },
                                  model: {
                                    value: _vm.ruleForm.pointCode,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.ruleForm, "pointCode", $$v)
                                    },
                                    expression: "ruleForm.pointCode",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "设备类型:",
                                  prop: "deviceType",
                                },
                              },
                              [
                                _c(
                                  "el-select",
                                  {
                                    model: {
                                      value: _vm.ruleForm.deviceType,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.ruleForm,
                                          "deviceType",
                                          $$v
                                        )
                                      },
                                      expression: "ruleForm.deviceType",
                                    },
                                  },
                                  [
                                    _c("el-option", {
                                      attrs: { label: "税控服务", value: 0 },
                                    }),
                                    _c("el-option", {
                                      attrs: { label: "税控盘", value: 1 },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.ruleForm.type === 2
                      ? _c(
                          "div",
                          [
                            _c(
                              "div",
                              { staticClass: "content" },
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: "开票项目:",
                                      prop: "invoiceItem",
                                    },
                                  },
                                  [
                                    _c(
                                      "el-select",
                                      {
                                        model: {
                                          value: _vm.ruleForm.invoiceItem,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.ruleForm,
                                              "invoiceItem",
                                              $$v
                                            )
                                          },
                                          expression: "ruleForm.invoiceItem",
                                        },
                                      },
                                      _vm._l(
                                        _vm.businessOrderType,
                                        function (item) {
                                          return _c("el-option", {
                                            key: item.code,
                                            attrs: {
                                              label: item.itemName,
                                              value: item.code,
                                            },
                                          })
                                        }
                                      ),
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: "订单类型",
                                      prop: "businessType",
                                    },
                                  },
                                  [
                                    _c(
                                      "el-checkbox-group",
                                      {
                                        model: {
                                          value: _vm.ruleForm.businessType,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.ruleForm,
                                              "businessType",
                                              $$v
                                            )
                                          },
                                          expression: "ruleForm.businessType",
                                        },
                                      },
                                      [
                                        _c(
                                          "el-checkbox",
                                          { attrs: { label: "0" } },
                                          [_vm._v("停车支付订单")]
                                        ),
                                        _c(
                                          "el-checkbox",
                                          { attrs: { label: "3" } },
                                          [_vm._v("停车卡订单")]
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: "计税方式:",
                                      prop: "taxMode",
                                    },
                                  },
                                  [
                                    _c(
                                      "el-select",
                                      {
                                        model: {
                                          value: _vm.ruleForm.taxMode,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.ruleForm,
                                              "taxMode",
                                              $$v
                                            )
                                          },
                                          expression: "ruleForm.taxMode",
                                        },
                                      },
                                      [
                                        _c("el-option", {
                                          attrs: {
                                            label: "一般纳税人",
                                            value: "0",
                                          },
                                        }),
                                        _c("el-option", {
                                          attrs: {
                                            label: "小规模纳税人",
                                            value: "1",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: "税率（%）:",
                                      prop: "taxRate",
                                    },
                                  },
                                  [
                                    _c(
                                      "el-select",
                                      {
                                        model: {
                                          value: _vm.ruleForm.taxRate,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.ruleForm,
                                              "taxRate",
                                              $$v
                                            )
                                          },
                                          expression: "ruleForm.taxRate",
                                        },
                                      },
                                      _vm._l(
                                        _vm.invoiceResource,
                                        function (item, index) {
                                          return _c("el-option", {
                                            key: index,
                                            attrs: { label: item, value: item },
                                          })
                                        }
                                      ),
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: "含税标志:",
                                      prop: "includeTax",
                                    },
                                  },
                                  [
                                    _c(
                                      "el-select",
                                      {
                                        model: {
                                          value: _vm.ruleForm.includeTax,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.ruleForm,
                                              "includeTax",
                                              $$v
                                            )
                                          },
                                          expression: "ruleForm.includeTax",
                                        },
                                      },
                                      [
                                        _c("el-option", {
                                          attrs: {
                                            label: "不含税",
                                            value: "0",
                                          },
                                        }),
                                        _c("el-option", {
                                          attrs: { label: "含税", value: "1" },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "el-form-item",
                              { attrs: { label: "APPKEY:", prop: "appKey" } },
                              [
                                _c("el-input", {
                                  attrs: {
                                    maxlength: "36",
                                    "show-word-limit": "",
                                    placeholder: "请输入APPKEY",
                                  },
                                  model: {
                                    value: _vm.ruleForm.appKey,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.ruleForm, "appKey", $$v)
                                    },
                                    expression: "ruleForm.appKey",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "APPSECRET:",
                                  prop: "appSecret",
                                },
                              },
                              [
                                _c("el-input", {
                                  attrs: {
                                    maxlength: "36",
                                    "show-word-limit": "",
                                    placeholder: "请输入APPSECRET",
                                  },
                                  model: {
                                    value: _vm.ruleForm.appSecret,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.ruleForm, "appSecret", $$v)
                                    },
                                    expression: "ruleForm.appSecret",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "请求地址:",
                                  prop: "requestUrl",
                                },
                              },
                              [
                                _c("el-input", {
                                  attrs: {
                                    maxlength: "200",
                                    "show-word-limit": "",
                                    placeholder: "请输入请求地址",
                                  },
                                  model: {
                                    value: _vm.ruleForm.requestUrl,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.ruleForm, "requestUrl", $$v)
                                    },
                                    expression: "ruleForm.requestUrl",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "纳税人名称:",
                                  prop: "taxpayerName",
                                },
                              },
                              [
                                _c("el-input", {
                                  attrs: {
                                    maxlength: "50",
                                    "show-word-limit": "",
                                    placeholder: "请输入纳税人名称",
                                  },
                                  model: {
                                    value: _vm.ruleForm.taxpayerName,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.ruleForm,
                                        "taxpayerName",
                                        $$v
                                      )
                                    },
                                    expression: "ruleForm.taxpayerName",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "纳税人识别号:",
                                  prop: "taxpayerNumber",
                                },
                              },
                              [
                                _c("el-input", {
                                  attrs: {
                                    maxlength: "50",
                                    "show-word-limit": "",
                                    placeholder: "请输入纳税人识别号",
                                  },
                                  model: {
                                    value: _vm.ruleForm.taxpayerNumber,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.ruleForm,
                                        "taxpayerNumber",
                                        $$v
                                      )
                                    },
                                    expression: "ruleForm.taxpayerNumber",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.ruleForm.type === 3
                      ? _c(
                          "div",
                          [
                            _c(
                              "div",
                              { staticClass: "content" },
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: "开票项目:",
                                      prop: "invoiceItem",
                                    },
                                  },
                                  [
                                    _c(
                                      "el-select",
                                      {
                                        model: {
                                          value: _vm.ruleForm.invoiceItem,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.ruleForm,
                                              "invoiceItem",
                                              $$v
                                            )
                                          },
                                          expression: "ruleForm.invoiceItem",
                                        },
                                      },
                                      _vm._l(
                                        _vm.businessOrderType,
                                        function (item) {
                                          return _c("el-option", {
                                            key: item.code,
                                            attrs: {
                                              label: item.itemName,
                                              value: item.code,
                                            },
                                          })
                                        }
                                      ),
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: "订单类型",
                                      prop: "businessType",
                                    },
                                  },
                                  [
                                    _c(
                                      "el-checkbox-group",
                                      {
                                        model: {
                                          value: _vm.ruleForm.businessType,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.ruleForm,
                                              "businessType",
                                              $$v
                                            )
                                          },
                                          expression: "ruleForm.businessType",
                                        },
                                      },
                                      [
                                        _c(
                                          "el-checkbox",
                                          { attrs: { label: "0" } },
                                          [_vm._v("停车支付订单")]
                                        ),
                                        _c(
                                          "el-checkbox",
                                          { attrs: { label: "3" } },
                                          [_vm._v("停车卡订单")]
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: "计税方式:",
                                      prop: "taxMode",
                                    },
                                  },
                                  [
                                    _c(
                                      "el-select",
                                      {
                                        model: {
                                          value: _vm.ruleForm.taxMode,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.ruleForm,
                                              "taxMode",
                                              $$v
                                            )
                                          },
                                          expression: "ruleForm.taxMode",
                                        },
                                      },
                                      [
                                        _c("el-option", {
                                          attrs: {
                                            label: "一般纳税人",
                                            value: "0",
                                          },
                                        }),
                                        _c("el-option", {
                                          attrs: {
                                            label: "小规模纳税人",
                                            value: "1",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: "税率（%）:",
                                      prop: "taxRate",
                                    },
                                  },
                                  [
                                    _c(
                                      "el-select",
                                      {
                                        model: {
                                          value: _vm.ruleForm.taxRate,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.ruleForm,
                                              "taxRate",
                                              $$v
                                            )
                                          },
                                          expression: "ruleForm.taxRate",
                                        },
                                      },
                                      _vm._l(
                                        _vm.invoiceResource,
                                        function (item, index) {
                                          return _c("el-option", {
                                            key: index,
                                            attrs: { label: item, value: item },
                                          })
                                        }
                                      ),
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: "含税标志:",
                                      prop: "includeTax",
                                    },
                                  },
                                  [
                                    _c(
                                      "el-select",
                                      {
                                        model: {
                                          value: _vm.ruleForm.includeTax,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.ruleForm,
                                              "includeTax",
                                              $$v
                                            )
                                          },
                                          expression: "ruleForm.includeTax",
                                        },
                                      },
                                      [
                                        _c("el-option", {
                                          attrs: {
                                            label: "不含税",
                                            value: "0",
                                          },
                                        }),
                                        _c("el-option", {
                                          attrs: { label: "含税", value: "1" },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "el-form-item",
                              { attrs: { label: "APPKEY:", prop: "appKey" } },
                              [
                                _c("el-input", {
                                  attrs: {
                                    maxlength: "36",
                                    "show-word-limit": "",
                                    placeholder: "请输入APPKEY",
                                  },
                                  model: {
                                    value: _vm.ruleForm.appKey,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.ruleForm, "appKey", $$v)
                                    },
                                    expression: "ruleForm.appKey",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "APPSECRET:",
                                  prop: "appSecret",
                                },
                              },
                              [
                                _c("el-input", {
                                  attrs: {
                                    maxlength: "36",
                                    "show-word-limit": "",
                                    placeholder: "请输入APPSECRET",
                                  },
                                  model: {
                                    value: _vm.ruleForm.appSecret,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.ruleForm, "appSecret", $$v)
                                    },
                                    expression: "ruleForm.appSecret",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "请求地址:",
                                  prop: "requestUrl",
                                },
                              },
                              [
                                _c("el-input", {
                                  attrs: {
                                    maxlength: "200",
                                    "show-word-limit": "",
                                    placeholder: "请输入请求地址",
                                  },
                                  model: {
                                    value: _vm.ruleForm.requestUrl,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.ruleForm, "requestUrl", $$v)
                                    },
                                    expression: "ruleForm.requestUrl",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "el-form-item",
                              { attrs: { label: "请求令牌:", prop: "token" } },
                              [
                                _c("el-input", {
                                  attrs: {
                                    maxlength: "200",
                                    "show-word-limit": "",
                                    placeholder: "请输入请求令牌",
                                  },
                                  model: {
                                    value: _vm.ruleForm.token,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.ruleForm, "token", $$v)
                                    },
                                    expression: "ruleForm.token",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "纳税人名称:",
                                  prop: "taxpayerName",
                                },
                              },
                              [
                                _c("el-input", {
                                  attrs: {
                                    maxlength: "50",
                                    "show-word-limit": "",
                                    placeholder: "请输入纳税人名称",
                                  },
                                  model: {
                                    value: _vm.ruleForm.taxpayerName,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.ruleForm,
                                        "taxpayerName",
                                        $$v
                                      )
                                    },
                                    expression: "ruleForm.taxpayerName",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "纳税人识别号:",
                                  prop: "taxpayerNumber",
                                },
                              },
                              [
                                _c("el-input", {
                                  attrs: {
                                    maxlength: "50",
                                    "show-word-limit": "",
                                    placeholder: "请输入纳税人识别号",
                                  },
                                  model: {
                                    value: _vm.ruleForm.taxpayerNumber,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.ruleForm,
                                        "taxpayerNumber",
                                        $$v
                                      )
                                    },
                                    expression: "ruleForm.taxpayerNumber",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "不动产地址:",
                                  prop: "detailAddress",
                                },
                              },
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    staticStyle: { "margin-bottom": "5px" },
                                    attrs: {
                                      label: "",
                                      prop: "realPropertyAddress",
                                    },
                                  },
                                  [
                                    _c("el-input", {
                                      attrs: {
                                        maxlength: "120",
                                        "show-word-limit": "",
                                        placeholder: "请输入省/市/区(县)",
                                      },
                                      model: {
                                        value: _vm.ruleForm.realPropertyAddress,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.ruleForm,
                                            "realPropertyAddress",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "ruleForm.realPropertyAddress",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c("el-input", {
                                  attrs: {
                                    maxlength: "120",
                                    "show-word-limit": "",
                                    placeholder: "请输入详细地址",
                                  },
                                  model: {
                                    value: _vm.ruleForm.detailAddress,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.ruleForm,
                                        "detailAddress",
                                        $$v
                                      )
                                    },
                                    expression: "ruleForm.detailAddress",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "el-form-item",
                              { attrs: { label: "是否跨市:" } },
                              [
                                _c(
                                  "el-select",
                                  {
                                    attrs: { placeholder: "请选择" },
                                    model: {
                                      value: _vm.ruleForm.crossCityFlag,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.ruleForm,
                                          "crossCityFlag",
                                          $$v
                                        )
                                      },
                                      expression: "ruleForm.crossCityFlag",
                                    },
                                  },
                                  _vm._l(
                                    _vm.crossCityFlagList,
                                    function (item) {
                                      return _c("el-option", {
                                        key: item.value,
                                        attrs: {
                                          label: item.label,
                                          value: item.value,
                                        },
                                      })
                                    }
                                  ),
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "el-form-item",
                              {
                                attrs: { label: "租赁时间:", prop: "rentDate" },
                              },
                              [
                                _c("el-date-picker", {
                                  attrs: {
                                    type: "daterange",
                                    "value-format": "yyyy-MM-dd",
                                    "range-separator": "至",
                                    "start-placeholder": "开始日期",
                                    "end-placeholder": "结束日期",
                                  },
                                  model: {
                                    value: _vm.ruleForm.rentDate,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.ruleForm, "rentDate", $$v)
                                    },
                                    expression: "ruleForm.rentDate",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "el-form-item",
                              { attrs: { label: "产权证书/不动产权证书:" } },
                              [
                                _c("el-input", {
                                  attrs: {
                                    maxlength: "40",
                                    "show-word-limit": "",
                                    placeholder: "请输入产权证书/不动产权证书",
                                  },
                                  model: {
                                    value: _vm.ruleForm.realPropertyCertificate,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.ruleForm,
                                        "realPropertyCertificate",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "ruleForm.realPropertyCertificate",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "el-form-item",
                              { attrs: { label: "面积单位:" } },
                              [
                                _c(
                                  "el-select",
                                  {
                                    attrs: { placeholder: "请选择" },
                                    model: {
                                      value: _vm.ruleForm.unit,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.ruleForm, "unit", $$v)
                                      },
                                      expression: "ruleForm.unit",
                                    },
                                  },
                                  _vm._l(_vm.unitList, function (item) {
                                    return _c("el-option", {
                                      key: item.value,
                                      attrs: {
                                        label: item.label,
                                        value: item.value,
                                      },
                                    })
                                  }),
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "分机号:",
                                  prop: "extensionNumber",
                                },
                              },
                              [
                                _c("el-input", {
                                  attrs: { placeholder: "请输入分机号" },
                                  model: {
                                    value: _vm.ruleForm.extensionNumber,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.ruleForm,
                                        "extensionNumber",
                                        $$v
                                      )
                                    },
                                    expression: "ruleForm.extensionNumber",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.ruleForm.type === 4
                      ? _c(
                          "div",
                          [
                            _c(
                              "div",
                              { staticClass: "content" },
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: "开票项目:",
                                      prop: "invoiceItem",
                                    },
                                  },
                                  [
                                    _c(
                                      "el-select",
                                      {
                                        model: {
                                          value: _vm.ruleForm.invoiceItem,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.ruleForm,
                                              "invoiceItem",
                                              $$v
                                            )
                                          },
                                          expression: "ruleForm.invoiceItem",
                                        },
                                      },
                                      _vm._l(
                                        _vm.businessOrderType,
                                        function (item) {
                                          return _c("el-option", {
                                            key: item.code,
                                            attrs: {
                                              label: item.itemName,
                                              value: item.code,
                                            },
                                          })
                                        }
                                      ),
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: "订单类型",
                                      prop: "businessType",
                                    },
                                  },
                                  [
                                    _c(
                                      "el-checkbox-group",
                                      {
                                        model: {
                                          value: _vm.ruleForm.businessType,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.ruleForm,
                                              "businessType",
                                              $$v
                                            )
                                          },
                                          expression: "ruleForm.businessType",
                                        },
                                      },
                                      [
                                        _c(
                                          "el-checkbox",
                                          { attrs: { label: "0" } },
                                          [_vm._v("停车支付订单")]
                                        ),
                                        _c(
                                          "el-checkbox",
                                          { attrs: { label: "3" } },
                                          [_vm._v("停车卡订单")]
                                        ),
                                        _c(
                                          "el-checkbox",
                                          { attrs: { label: "9" } },
                                          [_vm._v("商家优惠券订单")]
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: "计税方式:",
                                      prop: "taxMode",
                                    },
                                  },
                                  [
                                    _c(
                                      "el-select",
                                      {
                                        model: {
                                          value: _vm.ruleForm.taxMode,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.ruleForm,
                                              "taxMode",
                                              $$v
                                            )
                                          },
                                          expression: "ruleForm.taxMode",
                                        },
                                      },
                                      [
                                        _c("el-option", {
                                          attrs: {
                                            label: "一般纳税人",
                                            value: "0",
                                          },
                                        }),
                                        _c("el-option", {
                                          attrs: {
                                            label: "小规模纳税人",
                                            value: "1",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: "税率（%）:",
                                      prop: "taxRate",
                                    },
                                  },
                                  [
                                    _c(
                                      "el-select",
                                      {
                                        model: {
                                          value: _vm.ruleForm.taxRate,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.ruleForm,
                                              "taxRate",
                                              $$v
                                            )
                                          },
                                          expression: "ruleForm.taxRate",
                                        },
                                      },
                                      _vm._l(
                                        _vm.invoiceResource,
                                        function (item, index) {
                                          return _c("el-option", {
                                            key: index,
                                            attrs: { label: item, value: item },
                                          })
                                        }
                                      ),
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: "含税标志:",
                                      prop: "includeTax",
                                    },
                                  },
                                  [
                                    _c(
                                      "el-select",
                                      {
                                        model: {
                                          value: _vm.ruleForm.includeTax,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.ruleForm,
                                              "includeTax",
                                              $$v
                                            )
                                          },
                                          expression: "ruleForm.includeTax",
                                        },
                                      },
                                      [
                                        _c("el-option", {
                                          attrs: {
                                            label: "不含税",
                                            value: "0",
                                          },
                                        }),
                                        _c("el-option", {
                                          attrs: { label: "含税", value: "1" },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: "征税方式:",
                                      prop: "taxationMethod",
                                    },
                                  },
                                  [
                                    _c(
                                      "el-select",
                                      {
                                        model: {
                                          value: _vm.ruleForm.taxationMethod,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.ruleForm,
                                              "taxationMethod",
                                              $$v
                                            )
                                          },
                                          expression: "ruleForm.taxationMethod",
                                        },
                                      },
                                      [
                                        _c("el-option", {
                                          attrs: {
                                            label: "普通征税",
                                            value: "0",
                                          },
                                        }),
                                        _c("el-option", {
                                          attrs: {
                                            label: "差额征税",
                                            value: "2",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "企业私钥:",
                                  prop: "companyPriKey",
                                },
                              },
                              [
                                _c("el-input", {
                                  attrs: {
                                    maxlength: "200",
                                    "show-word-limit": "",
                                    placeholder: "请输入企业私钥",
                                  },
                                  model: {
                                    value: _vm.ruleForm.companyPriKey,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.ruleForm,
                                        "companyPriKey",
                                        $$v
                                      )
                                    },
                                    expression: "ruleForm.companyPriKey",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "平台公钥:",
                                  prop: "platFormPubKey",
                                },
                              },
                              [
                                _c("el-input", {
                                  attrs: {
                                    maxlength: "200",
                                    "show-word-limit": "",
                                    placeholder: "请输入平台公钥",
                                  },
                                  model: {
                                    value: _vm.ruleForm.platFormPubKey,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.ruleForm,
                                        "platFormPubKey",
                                        $$v
                                      )
                                    },
                                    expression: "ruleForm.platFormPubKey",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "开票终端标识:",
                                  prop: "invoiceTerminalCode",
                                },
                              },
                              [
                                _c("el-input", {
                                  attrs: {
                                    maxlength: "200",
                                    "show-word-limit": "",
                                    placeholder: "请输入开票终端标识",
                                  },
                                  model: {
                                    value: _vm.ruleForm.invoiceTerminalCode,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.ruleForm,
                                        "invoiceTerminalCode",
                                        $$v
                                      )
                                    },
                                    expression: "ruleForm.invoiceTerminalCode",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "机器编号:",
                                  prop: "machineCode",
                                },
                              },
                              [
                                _c("el-input", {
                                  attrs: {
                                    maxlength: "200",
                                    "show-word-limit": "",
                                    placeholder: "请输入机器编号",
                                  },
                                  model: {
                                    value: _vm.ruleForm.machineCode,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.ruleForm, "machineCode", $$v)
                                    },
                                    expression: "ruleForm.machineCode",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "企业税号:",
                                  prop: "enterpriseTaxCode",
                                },
                              },
                              [
                                _c("el-input", {
                                  attrs: {
                                    maxlength: "200",
                                    "show-word-limit": "",
                                    placeholder: "请输入企业税号",
                                  },
                                  model: {
                                    value: _vm.ruleForm.enterpriseTaxCode,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.ruleForm,
                                        "enterpriseTaxCode",
                                        $$v
                                      )
                                    },
                                    expression: "ruleForm.enterpriseTaxCode",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "企业代码:",
                                  prop: "enterpriseCode",
                                },
                              },
                              [
                                _c("el-input", {
                                  attrs: {
                                    maxlength: "200",
                                    "show-word-limit": "",
                                    placeholder: "请输入企业代码",
                                  },
                                  model: {
                                    value: _vm.ruleForm.enterpriseCode,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.ruleForm,
                                        "enterpriseCode",
                                        $$v
                                      )
                                    },
                                    expression: "ruleForm.enterpriseCode",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "请求地址:",
                                  prop: "requestUrl",
                                },
                              },
                              [
                                _c("el-input", {
                                  attrs: {
                                    maxlength: "200",
                                    "show-word-limit": "",
                                    placeholder: "请输入请求地址",
                                  },
                                  model: {
                                    value: _vm.ruleForm.requestUrl,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.ruleForm, "requestUrl", $$v)
                                    },
                                    expression: "ruleForm.requestUrl",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                  ],
                  1
                ),
                _c("el-col", { attrs: { span: 12 } }, [
                  _vm.ruleForm.type === 0
                    ? _c(
                        "div",
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "纳税人名称:",
                                prop: "taxpayerName",
                              },
                            },
                            [
                              _c("el-input", {
                                attrs: {
                                  maxlength: "50",
                                  "show-word-limit": "",
                                  placeholder: "请输入纳税人名称",
                                },
                                model: {
                                  value: _vm.ruleForm.taxpayerName,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.ruleForm, "taxpayerName", $$v)
                                  },
                                  expression: "ruleForm.taxpayerName",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "纳税人识别号:",
                                prop: "taxpayerNumber",
                              },
                            },
                            [
                              _c("el-input", {
                                attrs: {
                                  maxlength: "50",
                                  "show-word-limit": "",
                                  placeholder: "请输入纳税人识别号",
                                },
                                model: {
                                  value: _vm.ruleForm.taxpayerNumber,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.ruleForm,
                                      "taxpayerNumber",
                                      $$v
                                    )
                                  },
                                  expression: "ruleForm.taxpayerNumber",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "销货方名称:",
                                prop: "sellerName",
                              },
                            },
                            [
                              _c("el-input", {
                                attrs: {
                                  maxlength: "50",
                                  "show-word-limit": "",
                                  placeholder: "请输入销货方名称",
                                },
                                model: {
                                  value: _vm.ruleForm.sellerName,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.ruleForm, "sellerName", $$v)
                                  },
                                  expression: "ruleForm.sellerName",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "销货方识别号:",
                                prop: "sellerTaxpayerNumber",
                              },
                            },
                            [
                              _c("el-input", {
                                attrs: {
                                  maxlength: "30",
                                  "show-word-limit": "",
                                  placeholder: "请输入销货方识别号",
                                },
                                model: {
                                  value: _vm.ruleForm.sellerTaxpayerNumber,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.ruleForm,
                                      "sellerTaxpayerNumber",
                                      $$v
                                    )
                                  },
                                  expression: "ruleForm.sellerTaxpayerNumber",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "销货方地址:",
                                prop: "sellerAddress",
                              },
                            },
                            [
                              _c("el-input", {
                                attrs: {
                                  maxlength: "50",
                                  "show-word-limit": "",
                                  placeholder: "请输入销货方地址",
                                },
                                model: {
                                  value: _vm.ruleForm.sellerAddress,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.ruleForm, "sellerAddress", $$v)
                                  },
                                  expression: "ruleForm.sellerAddress",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "销货方电话:",
                                prop: "sellerPhoneNumber",
                              },
                            },
                            [
                              _c("el-input", {
                                attrs: {
                                  maxlength: "20",
                                  "show-word-limit": "",
                                  placeholder: "请输入销货方电话",
                                },
                                model: {
                                  value: _vm.ruleForm.sellerPhoneNumber,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.ruleForm,
                                      "sellerPhoneNumber",
                                      $$v
                                    )
                                  },
                                  expression: "ruleForm.sellerPhoneNumber",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "销货方银行名称:",
                                prop: "sellerBank",
                              },
                            },
                            [
                              _c("el-input", {
                                attrs: {
                                  maxlength: "50",
                                  "show-word-limit": "",
                                  placeholder: "请输入销货方银行名称",
                                },
                                model: {
                                  value: _vm.ruleForm.sellerBank,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.ruleForm, "sellerBank", $$v)
                                  },
                                  expression: "ruleForm.sellerBank",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "销货方银行账号:",
                                prop: "sellerBankAccount",
                              },
                            },
                            [
                              _c("el-input", {
                                attrs: {
                                  maxlength: "20",
                                  "show-word-limit": "",
                                  placeholder: "请输入销货方银行账号",
                                },
                                model: {
                                  value: _vm.ruleForm.sellerBankAccount,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.ruleForm,
                                      "sellerBankAccount",
                                      $$v
                                    )
                                  },
                                  expression: "ruleForm.sellerBankAccount",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            { attrs: { label: "收款人:", prop: "payee" } },
                            [
                              _c("el-input", {
                                attrs: {
                                  maxlength: "10",
                                  "show-word-limit": "",
                                  placeholder: "请输入收款人姓名",
                                },
                                model: {
                                  value: _vm.ruleForm.payee,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.ruleForm, "payee", $$v)
                                  },
                                  expression: "ruleForm.payee",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            { attrs: { label: "复核:", prop: "auditor" } },
                            [
                              _c("el-input", {
                                attrs: {
                                  maxlength: "10",
                                  "show-word-limit": "",
                                  placeholder: "请输入复核人姓名",
                                },
                                model: {
                                  value: _vm.ruleForm.auditor,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.ruleForm, "auditor", $$v)
                                  },
                                  expression: "ruleForm.auditor",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            { attrs: { label: "开票人:", prop: "drawer" } },
                            [
                              _c("el-input", {
                                attrs: {
                                  maxlength: "10",
                                  "show-word-limit": "",
                                  placeholder: "请输入开票人姓名",
                                },
                                model: {
                                  value: _vm.ruleForm.drawer,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.ruleForm, "drawer", $$v)
                                  },
                                  expression: "ruleForm.drawer",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "最小开票金额:",
                                prop: "minimumAmount",
                              },
                            },
                            [
                              _c("el-input", {
                                attrs: {
                                  maxlength: "8",
                                  "show-word-limit": "",
                                  placeholder:
                                    "请输入最小开票金额（0.01~1000）",
                                },
                                model: {
                                  value: _vm.ruleForm.minimumAmount,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.ruleForm, "minimumAmount", $$v)
                                  },
                                  expression: "ruleForm.minimumAmount",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "最大开票金额:",
                                prop: "maximumAmount",
                              },
                            },
                            [
                              _c("el-input", {
                                attrs: {
                                  maxlength: "8",
                                  "show-word-limit": "",
                                  placeholder:
                                    "请输入最大开票金额（0.01~1000）",
                                },
                                model: {
                                  value: _vm.ruleForm.maximumAmount,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.ruleForm, "maximumAmount", $$v)
                                  },
                                  expression: "ruleForm.maximumAmount",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.ruleForm.type === 1
                    ? _c(
                        "div",
                        [
                          _vm.ruleForm.deviceType === 1
                            ? _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "税控盘编号:",
                                    prop: "deviceCode",
                                  },
                                },
                                [
                                  _c("el-input", {
                                    attrs: {
                                      maxlength: "30",
                                      "show-word-limit": "",
                                      placeholder: "请输入税控盘编号",
                                    },
                                    model: {
                                      value: _vm.ruleForm.deviceCode,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.ruleForm,
                                          "deviceCode",
                                          $$v
                                        )
                                      },
                                      expression: "ruleForm.deviceCode",
                                    },
                                  }),
                                ],
                                1
                              )
                            : _vm._e(),
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "销货方名称:",
                                prop: "sellerName",
                              },
                            },
                            [
                              _c("el-input", {
                                attrs: {
                                  maxlength: "50",
                                  "show-word-limit": "",
                                  placeholder: "请输入销货方名称",
                                },
                                model: {
                                  value: _vm.ruleForm.sellerName,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.ruleForm, "sellerName", $$v)
                                  },
                                  expression: "ruleForm.sellerName",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "销货方识别号:",
                                prop: "sellerTaxpayerNumber",
                              },
                            },
                            [
                              _c("el-input", {
                                attrs: {
                                  maxlength: "30",
                                  "show-word-limit": "",
                                  placeholder: "请输入销货方识别号",
                                },
                                model: {
                                  value: _vm.ruleForm.sellerTaxpayerNumber,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.ruleForm,
                                      "sellerTaxpayerNumber",
                                      $$v
                                    )
                                  },
                                  expression: "ruleForm.sellerTaxpayerNumber",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "销货方地址:",
                                prop: "sellerAddress",
                              },
                            },
                            [
                              _c("el-input", {
                                attrs: {
                                  maxlength: "50",
                                  "show-word-limit": "",
                                  placeholder: "请输入销货方地址",
                                },
                                model: {
                                  value: _vm.ruleForm.sellerAddress,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.ruleForm, "sellerAddress", $$v)
                                  },
                                  expression: "ruleForm.sellerAddress",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "销货方电话:",
                                prop: "sellerPhoneNumber",
                              },
                            },
                            [
                              _c("el-input", {
                                attrs: {
                                  maxlength: "20",
                                  "show-word-limit": "",
                                  placeholder: "请输入销货方电话",
                                },
                                model: {
                                  value: _vm.ruleForm.sellerPhoneNumber,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.ruleForm,
                                      "sellerPhoneNumber",
                                      $$v
                                    )
                                  },
                                  expression: "ruleForm.sellerPhoneNumber",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "销货方银行名称:",
                                prop: "sellerBank",
                              },
                            },
                            [
                              _c("el-input", {
                                attrs: {
                                  maxlength: "50",
                                  "show-word-limit": "",
                                  placeholder: "请输入销货方银行名称",
                                },
                                model: {
                                  value: _vm.ruleForm.sellerBank,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.ruleForm, "sellerBank", $$v)
                                  },
                                  expression: "ruleForm.sellerBank",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "销货方银行账号:",
                                prop: "sellerBankAccount",
                              },
                            },
                            [
                              _c("el-input", {
                                attrs: {
                                  maxlength: "20",
                                  "show-word-limit": "",
                                  placeholder: "请输入销货方银行账号",
                                },
                                model: {
                                  value: _vm.ruleForm.sellerBankAccount,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.ruleForm,
                                      "sellerBankAccount",
                                      $$v
                                    )
                                  },
                                  expression: "ruleForm.sellerBankAccount",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            { attrs: { label: "收款人:", prop: "payee" } },
                            [
                              _c("el-input", {
                                attrs: {
                                  maxlength: "10",
                                  "show-word-limit": "",
                                  placeholder: "请输入收款人姓名",
                                },
                                model: {
                                  value: _vm.ruleForm.payee,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.ruleForm, "payee", $$v)
                                  },
                                  expression: "ruleForm.payee",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            { attrs: { label: "复核:", prop: "auditor" } },
                            [
                              _c("el-input", {
                                attrs: {
                                  maxlength: "10",
                                  "show-word-limit": "",
                                  placeholder: "请输入复核人姓名",
                                },
                                model: {
                                  value: _vm.ruleForm.auditor,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.ruleForm, "auditor", $$v)
                                  },
                                  expression: "ruleForm.auditor",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            { attrs: { label: "开票人:", prop: "drawer" } },
                            [
                              _c("el-input", {
                                attrs: {
                                  maxlength: "10",
                                  "show-word-limit": "",
                                  placeholder: "请输入开票人姓名",
                                },
                                model: {
                                  value: _vm.ruleForm.drawer,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.ruleForm, "drawer", $$v)
                                  },
                                  expression: "ruleForm.drawer",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "最小开票金额:",
                                prop: "minimumAmount",
                              },
                            },
                            [
                              _c("el-input", {
                                attrs: {
                                  maxlength: "8",
                                  "show-word-limit": "",
                                  placeholder:
                                    "请输入最小开票金额（0.01~1000）",
                                },
                                model: {
                                  value: _vm.ruleForm.minimumAmount,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.ruleForm, "minimumAmount", $$v)
                                  },
                                  expression: "ruleForm.minimumAmount",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "最大开票金额:",
                                prop: "maximumAmount",
                              },
                            },
                            [
                              _c("el-input", {
                                attrs: {
                                  maxlength: "8",
                                  "show-word-limit": "",
                                  placeholder:
                                    "请输入最大开票金额（0.01~1000）",
                                },
                                model: {
                                  value: _vm.ruleForm.maximumAmount,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.ruleForm, "maximumAmount", $$v)
                                  },
                                  expression: "ruleForm.maximumAmount",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "是否开启票量提醒:",
                                prop: "needRemind",
                              },
                            },
                            [
                              _c(
                                "el-radio-group",
                                {
                                  model: {
                                    value: _vm.ruleForm.needRemind,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.ruleForm, "needRemind", $$v)
                                    },
                                    expression: "ruleForm.needRemind",
                                  },
                                },
                                [
                                  _c("el-radio", { attrs: { label: 0 } }, [
                                    _vm._v("否"),
                                  ]),
                                  _c("el-radio", { attrs: { label: 1 } }, [
                                    _vm._v("是"),
                                  ]),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm.ruleForm.needRemind === 1
                            ? [
                                _vm.ruleForm.deviceType === 0
                                  ? _c(
                                      "el-form-item",
                                      {
                                        attrs: {
                                          label: "服务器编号:",
                                          prop: "serverNumber",
                                        },
                                      },
                                      [
                                        _c("el-input", {
                                          attrs: {
                                            maxlength: "30",
                                            "show-word-limit": "",
                                            placeholder: "请输入服务器编号",
                                          },
                                          model: {
                                            value: _vm.ruleForm.serverNumber,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.ruleForm,
                                                "serverNumber",
                                                $$v
                                              )
                                            },
                                            expression: "ruleForm.serverNumber",
                                          },
                                        }),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _vm.ruleForm.deviceType === 0
                                  ? _c(
                                      "el-form-item",
                                      {
                                        attrs: {
                                          label: "开票终端标识:",
                                          prop: "terminalId",
                                        },
                                      },
                                      [
                                        _c("el-input", {
                                          attrs: {
                                            maxlength: "30",
                                            "show-word-limit": "",
                                            placeholder: "请输入开票终端标识",
                                          },
                                          model: {
                                            value: _vm.ruleForm.terminalId,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.ruleForm,
                                                "terminalId",
                                                $$v
                                              )
                                            },
                                            expression: "ruleForm.terminalId",
                                          },
                                        }),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _vm.ruleForm.deviceType === 1
                                  ? _c(
                                      "el-form-item",
                                      {
                                        attrs: {
                                          label: "税控盘口令:",
                                          prop: "deviceToken",
                                        },
                                      },
                                      [
                                        _c("el-input", {
                                          attrs: {
                                            maxlength: "30",
                                            "show-word-limit": "",
                                            placeholder: "请输入税控盘口令",
                                          },
                                          model: {
                                            value: _vm.ruleForm.deviceToken,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.ruleForm,
                                                "deviceToken",
                                                $$v
                                              )
                                            },
                                            expression: "ruleForm.deviceToken",
                                          },
                                        }),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _vm.ruleForm.deviceType === 1
                                  ? _c(
                                      "el-form-item",
                                      {
                                        attrs: {
                                          label: "税务数字证书密码:",
                                          prop: "devicePassword",
                                        },
                                      },
                                      [
                                        _c("el-input", {
                                          attrs: {
                                            maxlength: "30",
                                            "show-word-limit": "",
                                            placeholder:
                                              "请输入税务数字证书密码",
                                          },
                                          model: {
                                            value: _vm.ruleForm.devicePassword,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.ruleForm,
                                                "devicePassword",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "ruleForm.devicePassword",
                                          },
                                        }),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: "提醒票量:",
                                      prop: "remindTicketVolume",
                                    },
                                  },
                                  [
                                    _c("el-input", {
                                      attrs: {
                                        maxlength: "30",
                                        "show-word-limit": "",
                                        placeholder:
                                          "请输入系统自动提醒的发票数量",
                                      },
                                      model: {
                                        value: _vm.ruleForm.remindTicketVolume,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.ruleForm,
                                            "remindTicketVolume",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "ruleForm.remindTicketVolume",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: "告警票量:",
                                      prop: "warningTicketVolume",
                                    },
                                  },
                                  [
                                    _c("el-input", {
                                      attrs: {
                                        maxlength: "30",
                                        "show-word-limit": "",
                                        placeholder:
                                          "请输入系统自动告警的发票数量",
                                      },
                                      model: {
                                        value: _vm.ruleForm.warningTicketVolume,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.ruleForm,
                                            "warningTicketVolume",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "ruleForm.warningTicketVolume",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: "短信通知:",
                                      prop: "messageReceiver",
                                    },
                                  },
                                  [
                                    _c("el-input", {
                                      attrs: {
                                        maxlength: "60",
                                        "show-word-limit": "",
                                        placeholder:
                                          "请输入需要票量通知的手机号，多个手机号可用 ； 区隔",
                                      },
                                      model: {
                                        value: _vm.ruleForm.messageReceiver,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.ruleForm,
                                            "messageReceiver",
                                            $$v
                                          )
                                        },
                                        expression: "ruleForm.messageReceiver",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: "邮件通知:",
                                      prop: "emailReceiver",
                                    },
                                  },
                                  [
                                    _c("el-input", {
                                      attrs: {
                                        maxlength: "100",
                                        "show-word-limit": "",
                                        placeholder:
                                          "请输入需要票量通知的邮箱地址，多个地址可用 ； 区隔",
                                      },
                                      model: {
                                        value: _vm.ruleForm.emailReceiver,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.ruleForm,
                                            "emailReceiver",
                                            $$v
                                          )
                                        },
                                        expression: "ruleForm.emailReceiver",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ]
                            : _vm._e(),
                        ],
                        2
                      )
                    : _vm._e(),
                  _vm.ruleForm.type === 2
                    ? _c(
                        "div",
                        [
                          _vm.ruleForm.deviceType === 1
                            ? _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "税控盘编号:",
                                    prop: "deviceCode",
                                  },
                                },
                                [
                                  _c("el-input", {
                                    attrs: {
                                      maxlength: "30",
                                      "show-word-limit": "",
                                      placeholder: "请输入税控盘编号",
                                    },
                                    model: {
                                      value: _vm.ruleForm.deviceCode,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.ruleForm,
                                          "deviceCode",
                                          $$v
                                        )
                                      },
                                      expression: "ruleForm.deviceCode",
                                    },
                                  }),
                                ],
                                1
                              )
                            : _vm._e(),
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "销货方名称:",
                                prop: "sellerName",
                              },
                            },
                            [
                              _c("el-input", {
                                attrs: {
                                  maxlength: "50",
                                  "show-word-limit": "",
                                  placeholder: "请输入销货方名称",
                                },
                                model: {
                                  value: _vm.ruleForm.sellerName,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.ruleForm, "sellerName", $$v)
                                  },
                                  expression: "ruleForm.sellerName",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "销货方识别号:",
                                prop: "sellerTaxpayerNumber",
                              },
                            },
                            [
                              _c("el-input", {
                                attrs: {
                                  maxlength: "30",
                                  "show-word-limit": "",
                                  placeholder: "请输入销货方识别号",
                                },
                                model: {
                                  value: _vm.ruleForm.sellerTaxpayerNumber,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.ruleForm,
                                      "sellerTaxpayerNumber",
                                      $$v
                                    )
                                  },
                                  expression: "ruleForm.sellerTaxpayerNumber",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "销货方地址:",
                                prop: "sellerAddress",
                              },
                            },
                            [
                              _c("el-input", {
                                attrs: {
                                  maxlength: "50",
                                  "show-word-limit": "",
                                  placeholder: "请输入销货方地址",
                                },
                                model: {
                                  value: _vm.ruleForm.sellerAddress,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.ruleForm, "sellerAddress", $$v)
                                  },
                                  expression: "ruleForm.sellerAddress",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "销货方电话:",
                                prop: "sellerPhoneNumber",
                              },
                            },
                            [
                              _c("el-input", {
                                attrs: {
                                  maxlength: "20",
                                  "show-word-limit": "",
                                  placeholder: "请输入销货方电话",
                                },
                                model: {
                                  value: _vm.ruleForm.sellerPhoneNumber,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.ruleForm,
                                      "sellerPhoneNumber",
                                      $$v
                                    )
                                  },
                                  expression: "ruleForm.sellerPhoneNumber",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "销货方银行名称:",
                                prop: "sellerBank",
                              },
                            },
                            [
                              _c("el-input", {
                                attrs: {
                                  maxlength: "50",
                                  "show-word-limit": "",
                                  placeholder: "请输入销货方银行名称",
                                },
                                model: {
                                  value: _vm.ruleForm.sellerBank,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.ruleForm, "sellerBank", $$v)
                                  },
                                  expression: "ruleForm.sellerBank",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "销货方银行账号:",
                                prop: "sellerBankAccount",
                              },
                            },
                            [
                              _c("el-input", {
                                attrs: {
                                  maxlength: "20",
                                  "show-word-limit": "",
                                  placeholder: "请输入销货方银行账号",
                                },
                                model: {
                                  value: _vm.ruleForm.sellerBankAccount,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.ruleForm,
                                      "sellerBankAccount",
                                      $$v
                                    )
                                  },
                                  expression: "ruleForm.sellerBankAccount",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            { attrs: { label: "收款人:", prop: "payee" } },
                            [
                              _c("el-input", {
                                attrs: {
                                  maxlength: "10",
                                  "show-word-limit": "",
                                  placeholder: "请输入收款人姓名",
                                },
                                model: {
                                  value: _vm.ruleForm.payee,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.ruleForm, "payee", $$v)
                                  },
                                  expression: "ruleForm.payee",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            { attrs: { label: "复核:", prop: "auditor" } },
                            [
                              _c("el-input", {
                                attrs: {
                                  maxlength: "10",
                                  "show-word-limit": "",
                                  placeholder: "请输入复核人姓名",
                                },
                                model: {
                                  value: _vm.ruleForm.auditor,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.ruleForm, "auditor", $$v)
                                  },
                                  expression: "ruleForm.auditor",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            { attrs: { label: "开票人:", prop: "drawer" } },
                            [
                              _c("el-input", {
                                attrs: {
                                  maxlength: "10",
                                  "show-word-limit": "",
                                  placeholder: "请输入开票人姓名",
                                },
                                model: {
                                  value: _vm.ruleForm.drawer,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.ruleForm, "drawer", $$v)
                                  },
                                  expression: "ruleForm.drawer",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "最小开票金额:",
                                prop: "minimumAmount",
                              },
                            },
                            [
                              _c("el-input", {
                                attrs: {
                                  maxlength: "8",
                                  "show-word-limit": "",
                                  placeholder:
                                    "请输入最小开票金额（0.01~1000）",
                                },
                                model: {
                                  value: _vm.ruleForm.minimumAmount,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.ruleForm, "minimumAmount", $$v)
                                  },
                                  expression: "ruleForm.minimumAmount",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "最大开票金额:",
                                prop: "maximumAmount",
                              },
                            },
                            [
                              _c("el-input", {
                                attrs: {
                                  maxlength: "8",
                                  "show-word-limit": "",
                                  placeholder:
                                    "请输入最大开票金额（0.01~1000）",
                                },
                                model: {
                                  value: _vm.ruleForm.maximumAmount,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.ruleForm, "maximumAmount", $$v)
                                  },
                                  expression: "ruleForm.maximumAmount",
                                },
                              }),
                            ],
                            1
                          ),
                          _vm.ruleForm.needRemind === 1
                            ? [
                                _vm.ruleForm.deviceType === 0
                                  ? _c(
                                      "el-form-item",
                                      {
                                        attrs: {
                                          label: "服务器编号:",
                                          prop: "serverNumber",
                                        },
                                      },
                                      [
                                        _c("el-input", {
                                          attrs: {
                                            maxlength: "30",
                                            "show-word-limit": "",
                                            placeholder: "请输入服务器编号",
                                          },
                                          model: {
                                            value: _vm.ruleForm.serverNumber,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.ruleForm,
                                                "serverNumber",
                                                $$v
                                              )
                                            },
                                            expression: "ruleForm.serverNumber",
                                          },
                                        }),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _vm.ruleForm.deviceType === 0
                                  ? _c(
                                      "el-form-item",
                                      {
                                        attrs: {
                                          label: "开票终端标识:",
                                          prop: "terminalId",
                                        },
                                      },
                                      [
                                        _c("el-input", {
                                          attrs: {
                                            maxlength: "30",
                                            "show-word-limit": "",
                                            placeholder: "请输入开票终端标识",
                                          },
                                          model: {
                                            value: _vm.ruleForm.terminalId,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.ruleForm,
                                                "terminalId",
                                                $$v
                                              )
                                            },
                                            expression: "ruleForm.terminalId",
                                          },
                                        }),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _vm.ruleForm.deviceType === 1
                                  ? _c(
                                      "el-form-item",
                                      {
                                        attrs: {
                                          label: "税控盘口令:",
                                          prop: "deviceToken",
                                        },
                                      },
                                      [
                                        _c("el-input", {
                                          attrs: {
                                            maxlength: "30",
                                            "show-word-limit": "",
                                            placeholder: "请输入税控盘口令",
                                          },
                                          model: {
                                            value: _vm.ruleForm.deviceToken,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.ruleForm,
                                                "deviceToken",
                                                $$v
                                              )
                                            },
                                            expression: "ruleForm.deviceToken",
                                          },
                                        }),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _vm.ruleForm.deviceType === 1
                                  ? _c(
                                      "el-form-item",
                                      {
                                        attrs: {
                                          label: "税务数字证书密码:",
                                          prop: "devicePassword",
                                        },
                                      },
                                      [
                                        _c("el-input", {
                                          attrs: {
                                            maxlength: "30",
                                            "show-word-limit": "",
                                            placeholder:
                                              "请输入税务数字证书密码",
                                          },
                                          model: {
                                            value: _vm.ruleForm.devicePassword,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.ruleForm,
                                                "devicePassword",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "ruleForm.devicePassword",
                                          },
                                        }),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: "提醒票量:",
                                      prop: "remindTicketVolume",
                                    },
                                  },
                                  [
                                    _c("el-input", {
                                      attrs: {
                                        maxlength: "30",
                                        "show-word-limit": "",
                                        placeholder:
                                          "请输入系统自动提醒的发票数量",
                                      },
                                      model: {
                                        value: _vm.ruleForm.remindTicketVolume,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.ruleForm,
                                            "remindTicketVolume",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "ruleForm.remindTicketVolume",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: "告警票量:",
                                      prop: "warningTicketVolume",
                                    },
                                  },
                                  [
                                    _c("el-input", {
                                      attrs: {
                                        maxlength: "30",
                                        "show-word-limit": "",
                                        placeholder:
                                          "请输入系统自动告警的发票数量",
                                      },
                                      model: {
                                        value: _vm.ruleForm.warningTicketVolume,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.ruleForm,
                                            "warningTicketVolume",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "ruleForm.warningTicketVolume",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: "短信通知:",
                                      prop: "messageReceiver",
                                    },
                                  },
                                  [
                                    _c("el-input", {
                                      attrs: {
                                        maxlength: "60",
                                        "show-word-limit": "",
                                        placeholder:
                                          "请输入需要票量通知的手机号，多个手机号可用 ； 区隔",
                                      },
                                      model: {
                                        value: _vm.ruleForm.messageReceiver,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.ruleForm,
                                            "messageReceiver",
                                            $$v
                                          )
                                        },
                                        expression: "ruleForm.messageReceiver",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: "邮件通知:",
                                      prop: "emailReceiver",
                                    },
                                  },
                                  [
                                    _c("el-input", {
                                      attrs: {
                                        maxlength: "100",
                                        "show-word-limit": "",
                                        placeholder:
                                          "请输入需要票量通知的邮箱地址，多个地址可用 ； 区隔",
                                      },
                                      model: {
                                        value: _vm.ruleForm.emailReceiver,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.ruleForm,
                                            "emailReceiver",
                                            $$v
                                          )
                                        },
                                        expression: "ruleForm.emailReceiver",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ]
                            : _vm._e(),
                        ],
                        2
                      )
                    : _vm._e(),
                  _vm.ruleForm.type === 3
                    ? _c(
                        "div",
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "销货方名称:",
                                prop: "sellerName",
                              },
                            },
                            [
                              _c("el-input", {
                                attrs: {
                                  maxlength: "50",
                                  "show-word-limit": "",
                                  placeholder: "请输入销货方名称",
                                },
                                model: {
                                  value: _vm.ruleForm.sellerName,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.ruleForm, "sellerName", $$v)
                                  },
                                  expression: "ruleForm.sellerName",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "销货方识别号:",
                                prop: "sellerTaxpayerNumber",
                              },
                            },
                            [
                              _c("el-input", {
                                attrs: {
                                  maxlength: "30",
                                  "show-word-limit": "",
                                  placeholder: "请输入销货方识别号",
                                },
                                model: {
                                  value: _vm.ruleForm.sellerTaxpayerNumber,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.ruleForm,
                                      "sellerTaxpayerNumber",
                                      $$v
                                    )
                                  },
                                  expression: "ruleForm.sellerTaxpayerNumber",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "销货方地址:",
                                prop: "sellerAddress",
                              },
                            },
                            [
                              _c("el-input", {
                                attrs: {
                                  maxlength: "50",
                                  "show-word-limit": "",
                                  placeholder: "请输入销货方地址",
                                },
                                model: {
                                  value: _vm.ruleForm.sellerAddress,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.ruleForm, "sellerAddress", $$v)
                                  },
                                  expression: "ruleForm.sellerAddress",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "销货方电话:",
                                prop: "sellerPhoneNumber",
                              },
                            },
                            [
                              _c("el-input", {
                                attrs: {
                                  maxlength: "20",
                                  "show-word-limit": "",
                                  placeholder: "请输入销货方电话",
                                },
                                model: {
                                  value: _vm.ruleForm.sellerPhoneNumber,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.ruleForm,
                                      "sellerPhoneNumber",
                                      $$v
                                    )
                                  },
                                  expression: "ruleForm.sellerPhoneNumber",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "销货方银行名称:",
                                prop: "sellerBank",
                              },
                            },
                            [
                              _c("el-input", {
                                attrs: {
                                  maxlength: "50",
                                  "show-word-limit": "",
                                  placeholder: "请输入销货方银行名称",
                                },
                                model: {
                                  value: _vm.ruleForm.sellerBank,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.ruleForm, "sellerBank", $$v)
                                  },
                                  expression: "ruleForm.sellerBank",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "销货方银行账号:",
                                prop: "sellerBankAccount",
                              },
                            },
                            [
                              _c("el-input", {
                                attrs: {
                                  maxlength: "20",
                                  "show-word-limit": "",
                                  placeholder: "请输入销货方银行账号",
                                },
                                model: {
                                  value: _vm.ruleForm.sellerBankAccount,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.ruleForm,
                                      "sellerBankAccount",
                                      $$v
                                    )
                                  },
                                  expression: "ruleForm.sellerBankAccount",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            { attrs: { label: "收款人:", prop: "payee" } },
                            [
                              _c("el-input", {
                                attrs: {
                                  maxlength: "10",
                                  "show-word-limit": "",
                                  placeholder: "请输入收款人姓名",
                                },
                                model: {
                                  value: _vm.ruleForm.payee,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.ruleForm, "payee", $$v)
                                  },
                                  expression: "ruleForm.payee",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            { attrs: { label: "复核:", prop: "auditor" } },
                            [
                              _c("el-input", {
                                attrs: {
                                  maxlength: "10",
                                  "show-word-limit": "",
                                  placeholder: "请输入复核人姓名",
                                },
                                model: {
                                  value: _vm.ruleForm.auditor,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.ruleForm, "auditor", $$v)
                                  },
                                  expression: "ruleForm.auditor",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            { attrs: { label: "开票人:", prop: "drawer" } },
                            [
                              _c("el-input", {
                                attrs: {
                                  maxlength: "10",
                                  "show-word-limit": "",
                                  placeholder: "请输入开票人姓名",
                                },
                                model: {
                                  value: _vm.ruleForm.drawer,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.ruleForm, "drawer", $$v)
                                  },
                                  expression: "ruleForm.drawer",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "最小开票金额:",
                                prop: "minimumAmount",
                              },
                            },
                            [
                              _c("el-input", {
                                attrs: {
                                  maxlength: "8",
                                  "show-word-limit": "",
                                  placeholder:
                                    "请输入最小开票金额（0.01~1000）",
                                },
                                model: {
                                  value: _vm.ruleForm.minimumAmount,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.ruleForm, "minimumAmount", $$v)
                                  },
                                  expression: "ruleForm.minimumAmount",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "最大开票金额:",
                                prop: "maximumAmount",
                              },
                            },
                            [
                              _c("el-input", {
                                attrs: {
                                  maxlength: "8",
                                  "show-word-limit": "",
                                  placeholder:
                                    "请输入最大开票金额（0.01~1000）",
                                },
                                model: {
                                  value: _vm.ruleForm.maximumAmount,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.ruleForm, "maximumAmount", $$v)
                                  },
                                  expression: "ruleForm.maximumAmount",
                                },
                              }),
                            ],
                            1
                          ),
                          _vm.ruleForm.needRemind === 1
                            ? [
                                _vm.ruleForm.deviceType === 0
                                  ? _c(
                                      "el-form-item",
                                      {
                                        attrs: {
                                          label: "服务器编号:",
                                          prop: "serverNumber",
                                        },
                                      },
                                      [
                                        _c("el-input", {
                                          attrs: {
                                            maxlength: "30",
                                            "show-word-limit": "",
                                            placeholder: "请输入服务器编号",
                                          },
                                          model: {
                                            value: _vm.ruleForm.serverNumber,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.ruleForm,
                                                "serverNumber",
                                                $$v
                                              )
                                            },
                                            expression: "ruleForm.serverNumber",
                                          },
                                        }),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _vm.ruleForm.deviceType === 0
                                  ? _c(
                                      "el-form-item",
                                      {
                                        attrs: {
                                          label: "开票终端标识:",
                                          prop: "terminalId",
                                        },
                                      },
                                      [
                                        _c("el-input", {
                                          attrs: {
                                            maxlength: "30",
                                            "show-word-limit": "",
                                            placeholder: "请输入开票终端标识",
                                          },
                                          model: {
                                            value: _vm.ruleForm.terminalId,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.ruleForm,
                                                "terminalId",
                                                $$v
                                              )
                                            },
                                            expression: "ruleForm.terminalId",
                                          },
                                        }),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _vm.ruleForm.deviceType === 1
                                  ? _c(
                                      "el-form-item",
                                      {
                                        attrs: {
                                          label: "税控盘口令:",
                                          prop: "deviceToken",
                                        },
                                      },
                                      [
                                        _c("el-input", {
                                          attrs: {
                                            maxlength: "30",
                                            "show-word-limit": "",
                                            placeholder: "请输入税控盘口令",
                                          },
                                          model: {
                                            value: _vm.ruleForm.deviceToken,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.ruleForm,
                                                "deviceToken",
                                                $$v
                                              )
                                            },
                                            expression: "ruleForm.deviceToken",
                                          },
                                        }),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _vm.ruleForm.deviceType === 1
                                  ? _c(
                                      "el-form-item",
                                      {
                                        attrs: {
                                          label: "税务数字证书密码:",
                                          prop: "devicePassword",
                                        },
                                      },
                                      [
                                        _c("el-input", {
                                          attrs: {
                                            maxlength: "30",
                                            "show-word-limit": "",
                                            placeholder:
                                              "请输入税务数字证书密码",
                                          },
                                          model: {
                                            value: _vm.ruleForm.devicePassword,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.ruleForm,
                                                "devicePassword",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "ruleForm.devicePassword",
                                          },
                                        }),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: "提醒票量:",
                                      prop: "remindTicketVolume",
                                    },
                                  },
                                  [
                                    _c("el-input", {
                                      attrs: {
                                        maxlength: "30",
                                        "show-word-limit": "",
                                        placeholder:
                                          "请输入系统自动提醒的发票数量",
                                      },
                                      model: {
                                        value: _vm.ruleForm.remindTicketVolume,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.ruleForm,
                                            "remindTicketVolume",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "ruleForm.remindTicketVolume",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: "告警票量:",
                                      prop: "warningTicketVolume",
                                    },
                                  },
                                  [
                                    _c("el-input", {
                                      attrs: {
                                        maxlength: "30",
                                        "show-word-limit": "",
                                        placeholder:
                                          "请输入系统自动告警的发票数量",
                                      },
                                      model: {
                                        value: _vm.ruleForm.warningTicketVolume,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.ruleForm,
                                            "warningTicketVolume",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "ruleForm.warningTicketVolume",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: "短信通知:",
                                      prop: "messageReceiver",
                                    },
                                  },
                                  [
                                    _c("el-input", {
                                      attrs: {
                                        maxlength: "60",
                                        "show-word-limit": "",
                                        placeholder:
                                          "请输入需要票量通知的手机号，多个手机号可用 ； 区隔",
                                      },
                                      model: {
                                        value: _vm.ruleForm.messageReceiver,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.ruleForm,
                                            "messageReceiver",
                                            $$v
                                          )
                                        },
                                        expression: "ruleForm.messageReceiver",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: "邮件通知:",
                                      prop: "emailReceiver",
                                    },
                                  },
                                  [
                                    _c("el-input", {
                                      attrs: {
                                        maxlength: "100",
                                        "show-word-limit": "",
                                        placeholder:
                                          "请输入需要票量通知的邮箱地址，多个地址可用 ； 区隔",
                                      },
                                      model: {
                                        value: _vm.ruleForm.emailReceiver,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.ruleForm,
                                            "emailReceiver",
                                            $$v
                                          )
                                        },
                                        expression: "ruleForm.emailReceiver",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ]
                            : _vm._e(),
                        ],
                        2
                      )
                    : _vm._e(),
                  _vm.ruleForm.type === 4
                    ? _c(
                        "div",
                        [
                          _vm.ruleForm.deviceType === 1
                            ? _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "税控盘编号:",
                                    prop: "deviceCode",
                                  },
                                },
                                [
                                  _c("el-input", {
                                    attrs: {
                                      maxlength: "30",
                                      "show-word-limit": "",
                                      placeholder: "请输入税控盘编号",
                                    },
                                    model: {
                                      value: _vm.ruleForm.deviceCode,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.ruleForm,
                                          "deviceCode",
                                          $$v
                                        )
                                      },
                                      expression: "ruleForm.deviceCode",
                                    },
                                  }),
                                ],
                                1
                              )
                            : _vm._e(),
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "销货方名称:",
                                prop: "sellerName",
                              },
                            },
                            [
                              _c("el-input", {
                                attrs: {
                                  maxlength: "50",
                                  "show-word-limit": "",
                                  placeholder: "请输入销货方名称",
                                },
                                model: {
                                  value: _vm.ruleForm.sellerName,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.ruleForm, "sellerName", $$v)
                                  },
                                  expression: "ruleForm.sellerName",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "销货方识别号:",
                                prop: "sellerTaxpayerNumber",
                              },
                            },
                            [
                              _c("el-input", {
                                attrs: {
                                  maxlength: "30",
                                  "show-word-limit": "",
                                  placeholder: "请输入销货方识别号",
                                },
                                model: {
                                  value: _vm.ruleForm.sellerTaxpayerNumber,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.ruleForm,
                                      "sellerTaxpayerNumber",
                                      $$v
                                    )
                                  },
                                  expression: "ruleForm.sellerTaxpayerNumber",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "销货方地址:",
                                prop: "sellerAddress",
                              },
                            },
                            [
                              _c("el-input", {
                                attrs: {
                                  maxlength: "50",
                                  "show-word-limit": "",
                                  placeholder: "请输入销货方地址",
                                },
                                model: {
                                  value: _vm.ruleForm.sellerAddress,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.ruleForm, "sellerAddress", $$v)
                                  },
                                  expression: "ruleForm.sellerAddress",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "销货方电话:",
                                prop: "sellerPhoneNumber",
                              },
                            },
                            [
                              _c("el-input", {
                                attrs: {
                                  maxlength: "20",
                                  "show-word-limit": "",
                                  placeholder: "请输入销货方电话",
                                },
                                model: {
                                  value: _vm.ruleForm.sellerPhoneNumber,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.ruleForm,
                                      "sellerPhoneNumber",
                                      $$v
                                    )
                                  },
                                  expression: "ruleForm.sellerPhoneNumber",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "销货方银行名称:",
                                prop: "sellerBank",
                              },
                            },
                            [
                              _c("el-input", {
                                attrs: {
                                  maxlength: "50",
                                  "show-word-limit": "",
                                  placeholder: "请输入销货方银行名称",
                                },
                                model: {
                                  value: _vm.ruleForm.sellerBank,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.ruleForm, "sellerBank", $$v)
                                  },
                                  expression: "ruleForm.sellerBank",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "销货方银行账号:",
                                prop: "sellerBankAccount",
                              },
                            },
                            [
                              _c("el-input", {
                                attrs: {
                                  maxlength: "23",
                                  "show-word-limit": "",
                                  placeholder: "请输入销货方银行账号",
                                },
                                model: {
                                  value: _vm.ruleForm.sellerBankAccount,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.ruleForm,
                                      "sellerBankAccount",
                                      $$v
                                    )
                                  },
                                  expression: "ruleForm.sellerBankAccount",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            { attrs: { label: "收款人:", prop: "payee" } },
                            [
                              _c("el-input", {
                                attrs: {
                                  maxlength: "10",
                                  "show-word-limit": "",
                                  placeholder: "请输入收款人姓名",
                                },
                                model: {
                                  value: _vm.ruleForm.payee,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.ruleForm, "payee", $$v)
                                  },
                                  expression: "ruleForm.payee",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            { attrs: { label: "复核:", prop: "auditor" } },
                            [
                              _c("el-input", {
                                attrs: {
                                  maxlength: "10",
                                  "show-word-limit": "",
                                  placeholder: "请输入复核人姓名",
                                },
                                model: {
                                  value: _vm.ruleForm.auditor,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.ruleForm, "auditor", $$v)
                                  },
                                  expression: "ruleForm.auditor",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            { attrs: { label: "开票人:", prop: "drawer" } },
                            [
                              _c("el-input", {
                                attrs: {
                                  maxlength: "10",
                                  "show-word-limit": "",
                                  placeholder: "请输入开票人姓名",
                                },
                                model: {
                                  value: _vm.ruleForm.drawer,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.ruleForm, "drawer", $$v)
                                  },
                                  expression: "ruleForm.drawer",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "最小开票金额:",
                                prop: "minimumAmount",
                              },
                            },
                            [
                              _c("el-input", {
                                attrs: {
                                  maxlength: "8",
                                  "show-word-limit": "",
                                  placeholder:
                                    "请输入最小开票金额（0.01~1000）",
                                },
                                model: {
                                  value: _vm.ruleForm.minimumAmount,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.ruleForm, "minimumAmount", $$v)
                                  },
                                  expression: "ruleForm.minimumAmount",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "最大开票金额:",
                                prop: "maximumAmount",
                              },
                            },
                            [
                              _c("el-input", {
                                attrs: {
                                  maxlength: "8",
                                  "show-word-limit": "",
                                  placeholder:
                                    "请输入最大开票金额（0.01~1000）",
                                },
                                model: {
                                  value: _vm.ruleForm.maximumAmount,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.ruleForm, "maximumAmount", $$v)
                                  },
                                  expression: "ruleForm.maximumAmount",
                                },
                              }),
                            ],
                            1
                          ),
                          _vm.ruleForm.needRemind === 1
                            ? [
                                _vm.ruleForm.deviceType === 0
                                  ? _c(
                                      "el-form-item",
                                      {
                                        attrs: {
                                          label: "服务器编号:",
                                          prop: "serverNumber",
                                        },
                                      },
                                      [
                                        _c("el-input", {
                                          attrs: {
                                            maxlength: "30",
                                            "show-word-limit": "",
                                            placeholder: "请输入服务器编号",
                                          },
                                          model: {
                                            value: _vm.ruleForm.serverNumber,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.ruleForm,
                                                "serverNumber",
                                                $$v
                                              )
                                            },
                                            expression: "ruleForm.serverNumber",
                                          },
                                        }),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _vm.ruleForm.deviceType === 0
                                  ? _c(
                                      "el-form-item",
                                      {
                                        attrs: {
                                          label: "开票终端标识:",
                                          prop: "terminalId",
                                        },
                                      },
                                      [
                                        _c("el-input", {
                                          attrs: {
                                            maxlength: "30",
                                            "show-word-limit": "",
                                            placeholder: "请输入开票终端标识",
                                          },
                                          model: {
                                            value: _vm.ruleForm.terminalId,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.ruleForm,
                                                "terminalId",
                                                $$v
                                              )
                                            },
                                            expression: "ruleForm.terminalId",
                                          },
                                        }),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _vm.ruleForm.deviceType === 1
                                  ? _c(
                                      "el-form-item",
                                      {
                                        attrs: {
                                          label: "税控盘口令:",
                                          prop: "deviceToken",
                                        },
                                      },
                                      [
                                        _c("el-input", {
                                          attrs: {
                                            maxlength: "30",
                                            "show-word-limit": "",
                                            placeholder: "请输入税控盘口令",
                                          },
                                          model: {
                                            value: _vm.ruleForm.deviceToken,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.ruleForm,
                                                "deviceToken",
                                                $$v
                                              )
                                            },
                                            expression: "ruleForm.deviceToken",
                                          },
                                        }),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _vm.ruleForm.deviceType === 1
                                  ? _c(
                                      "el-form-item",
                                      {
                                        attrs: {
                                          label: "税务数字证书密码:",
                                          prop: "devicePassword",
                                        },
                                      },
                                      [
                                        _c("el-input", {
                                          attrs: {
                                            maxlength: "30",
                                            "show-word-limit": "",
                                            placeholder:
                                              "请输入税务数字证书密码",
                                          },
                                          model: {
                                            value: _vm.ruleForm.devicePassword,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.ruleForm,
                                                "devicePassword",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "ruleForm.devicePassword",
                                          },
                                        }),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: "提醒票量:",
                                      prop: "remindTicketVolume",
                                    },
                                  },
                                  [
                                    _c("el-input", {
                                      attrs: {
                                        maxlength: "30",
                                        "show-word-limit": "",
                                        placeholder:
                                          "请输入系统自动提醒的发票数量",
                                      },
                                      model: {
                                        value: _vm.ruleForm.remindTicketVolume,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.ruleForm,
                                            "remindTicketVolume",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "ruleForm.remindTicketVolume",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: "告警票量:",
                                      prop: "warningTicketVolume",
                                    },
                                  },
                                  [
                                    _c("el-input", {
                                      attrs: {
                                        maxlength: "30",
                                        "show-word-limit": "",
                                        placeholder:
                                          "请输入系统自动告警的发票数量",
                                      },
                                      model: {
                                        value: _vm.ruleForm.warningTicketVolume,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.ruleForm,
                                            "warningTicketVolume",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "ruleForm.warningTicketVolume",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: "短信通知:",
                                      prop: "messageReceiver",
                                    },
                                  },
                                  [
                                    _c("el-input", {
                                      attrs: {
                                        maxlength: "60",
                                        "show-word-limit": "",
                                        placeholder:
                                          "请输入需要票量通知的手机号，多个手机号可用 ； 区隔",
                                      },
                                      model: {
                                        value: _vm.ruleForm.messageReceiver,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.ruleForm,
                                            "messageReceiver",
                                            $$v
                                          )
                                        },
                                        expression: "ruleForm.messageReceiver",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: "邮件通知:",
                                      prop: "emailReceiver",
                                    },
                                  },
                                  [
                                    _c("el-input", {
                                      attrs: {
                                        maxlength: "100",
                                        "show-word-limit": "",
                                        placeholder:
                                          "请输入需要票量通知的邮箱地址，多个地址可用 ； 区隔",
                                      },
                                      model: {
                                        value: _vm.ruleForm.emailReceiver,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.ruleForm,
                                            "emailReceiver",
                                            $$v
                                          )
                                        },
                                        expression: "ruleForm.emailReceiver",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ]
                            : _vm._e(),
                        ],
                        2
                      )
                    : _vm._e(),
                ]),
              ],
              1
            ),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }